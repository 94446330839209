.css-ha3bif-MuiTimelineItem-root:before {
    display: none;
}
textarea, textarea:active, textarea:focus {
    outline: none !important;
    padding: 5px;
    border: 2px solid #B6CDBC;
    background-color: inherit;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
}
select, select > option {
    padding: 5px;
    border: 2px solid #B6CDBC;
    color: #00000099 !important;
    background-color: inherit !important;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
    font-size: 20px !important;
}
input {
    margin-top: 5px;
    /* background-color: #F8F9FA !important; */
}
a.privacy {
    font-weight: 400;
}
input[type='checkbox'], input[type='radio'], .Mui-checked {
    color: #7B3F00 !important;
} 
input.form-check-input {
    color: white !important;
    border: none;
    border: 2px solid #7B3F00 !important;
} 
input.form-check-input:checked{
    color: white !important;
    border: none;
    background-color: #7B3F00 !important;
} 
@media only screen and (max-width : 768px) {
    h4.login_text{
        font-size: 17px !important;
    }
}
h4.login_text{
    font-size: 26px;
}