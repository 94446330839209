* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0px;
}

:root {
  --light-green: #D4FB7980;
  --asparagus-light: #92900080;
  --mocha: #94520080;
  --asparagus: #929000;
  --green: #4F8F0080;
  --sky: #76C4C780;
  --gray: #e5e4e9;

  --primary: #d4d2d2;
  --secondary: #797979;
  --info: #76C4C780;
  --success: #008F0052;
  --warning: #EE220C;
}


.page {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 80vh;
  /* color:var(--secondary); */
  z-index: 1;
}

.home-page {
  padding: 0 !important;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.detail-page-link:hover {
  cursor: pointer;
}

.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}

p.scroll_text {
  white-space: nowrap;
  color: #646464d5;
}

.bg {
  background-color: #E3BC9A21;
}

.swal-modal .swal-text {
  text-align: center !important;
}