.aler-msg {
    margin-top: 10px;
    font-size: 14px;
    color: #00000099;
}

.back-btn, .back-btn:hover, .back-btn:link, .back-btn:active {
    color: white;
    border: none !important;
    background-color: #7B3F00;

}
.next-btn, .next-btn:hover, .next-btn:link, .next-btn:active {
    color: #7B3F00;
    border: none;
    border: 2px solid #7B3F00;
}
.btn-outline-warning {
    border: none !important;
}