.info-btn {
    color: white !important;
    background-color: #A0522D !important;
    margin-bottom: 15px;
    padding: 8px !important;
}

.info-btn:hover,
.info-btn:link,
.info-btn:active,
.info-btn:visited {
    color: #A0522D !important;
    background-color: white !important;
    box-shadow: none;
    border: 2px solid #A0522D;
    border-radius: 5px;
    margin: -2px;
}

.buy-btn {
    color: white !important;
    background-color: #7B3F00 !important;
}

.buy-btn:hover,
.buy-btn:link,
.buy-btn:active,
.buy-btn:visited,
.active-buy-btn {
    margin: -2px;
    border: 2px solid #7B3F00 !important;
    color: #7B3F00 !important;
    background-color: white !important;
}
.buy-btn:disabled{
    color: white !important;
    background-color: hsl(31, 100%, 24%,0.6) !important;
    cursor: not-allowed !important;
    z-index: 100 !important;
}
.buy-btn[data-disabled=true],.buy-btn[data-disabled=true]:hover{
    color: white !important;
    background-color: hsl(31, 100%, 24%,0.6) !important;
    cursor: not-allowed !important;
    z-index: 100 !important;
    border:none !important;
    margin: 0px !important;

}

.sold-out {
    font-size: 0.875rem !important;
    padding: 8px !important;
    margin: 10px 0px;
    color: red;
    border-radius: 5px;
    border: 2px solid red;
    font-weight: 500 !important;
    cursor: auto;
    display: block;
}