/* .top-section {
    position: relative;
}
.heading {
    position: absolute;
    top: 15%;
    left: 70%;
    transform: translate(0, -50%);
    padding: 10px;
    color:aliceblue;
    margin-bottom: 20px;
}
.heading h2.hero {
    font-size: 72px;
    margin-right: 20px;
}
.heading h4 {
    font-size: 42px;
    margin-right: 20px;
    font-weight: 400;
}
video {
    height: 91vh;
    width: 99.7vw;
    object-fit: cover;
}
.top-section .p {
    margin: 0 20px;

} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
    .heading {
        position: absolute;
        top: 30%;
        left: 30%;
        transform: translate(0, -50%);
    }
    video {
        height: 61vh;
    }
    .heading h2.hero {
        font-size: 26px;
    }
} */


/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (max-width: 768px) {
    .heading {
        position: absolute;
        top: 20%;
        left: 45%;
        transform: translate(0, -50%);
    }
    video {
        height: 71vh;
    }
    .heading h2.hero {
        font-size: 26px;
    }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (max-width: 992px) { */
/* .video-wrapper{
        height: 50vh;
    } */
/* } */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 993px) {
    
} */

/* .top-section {
    position: relative;
}
.heading {
    position: absolute;
    top: 40%;
    left: 45%;
    transform: translate(0, -50%);
    padding: 10px;
    color:aliceblue;
}
.heading h2 {
    font-size: 46px;
} */
@media only screen and (max-width : 992px) {
    .main {
        height: 55vh !important;
    }

    .text1 {
        position: absolute;
        left: 15% !important;
        top: 10%;
    }

    .text2 {
        position: absolute;
        left: 15%;
        top: 80%;
    }

    .text3 {
        position: absolute;
        left: 80%;
        top: 80%;
    }

    .text4 {
        position: absolute;
        left: 80%;
        top: 10%;
    }

    .text5 {
        position: absolute;
        left: 50% !important;
        top: 10%;
    }

    .text6 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50px, -50px);
    }

    .banner {
        padding: 25px 10px !important;
        font-size: 30px !important;
    }

}

@media only screen and (max-width : 768px) {
    .main {
        height: 38vh !important;
    }
    .banner {
        padding: 25px 10px !important;
        font-size: 30px !important;
    }
}

.main {
    position: relative;
    width: 100%;
    height: 65vh;
    font-size: 25px;
    overflow: hidden;
    font-weight: 600;
}

.text1 {
    position: absolute;
    left: 10%;
    top: 10%;
}

.text2 {
    position: absolute;
    left: 40%;
    top: 80%;
    transform: translate(-40px, -80px);
}

.text3 {
    position: absolute;
    left: 55%;
    top: 80%;
}

.text4 {
    position: absolute;
    left: 70%;
    top: 10%;
}

.text44 {
    position: absolute;
    left: 50%;
    top: 10%;
}

.text5 {
    position: absolute;
    left: 20%;
    top: 70%;
}

.text6 {
    position: absolute;
    left: 45%;
    top: 60%;
    transform: translate(-50px, -50px);
}

.banner {
    position: absolute;
    top: 50%;
    left: -100%;
    padding: 55px 10px;
    background-color: #7B3F00;
    width: 100%;
    transform: translate(-0, -50%);
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: white;
    text-align: center;
}