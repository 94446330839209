iframe {
    width: 100%;
    height: 420px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    iframe {
        height: 260px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
    iframe {
        height: 300px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    iframe {
        height: 320px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 993px) {
    
}

.home-page p, .home-page li {
    color: #000000DE;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
