nav {
    padding: 0;
    margin: 0;
    background: #f8f9fa;
    /*box-shadow: 0 0 0 0 rgba(182, 185, 196, 0.068), 0 4px 16px 0 rgba(194, 200, 206, 0.658);*/
    box-shadow: 0 0 0 0 rgb(182 185 196 / 7%), 0 1px 9px 0 rgb(194 200 206 / 66%);
}

.navbar {
    padding: 0 !important;
}

.navbar-menu {
    margin-left: 80px;
}

.navbar-nav {
    display: flex;
    gap: 10px;
}

.nav-item {
    position: relative;
}

@media only screen and (max-width: 992px) {
    li.nav-item .parent_menu li {
        padding: 5px 0px !important;
    }

    .nav-item .parent_menu {
        left: 0px !important;
    }

    .nav-item:hover .parent_menu {
        top: 125% !important;
    }
}

.nav-item .parent_menu.login {
    width: auto !important;
}

.nav-item .parent_menu {
    visibility: hidden;
    margin-top: 20px;
    position: absolute;
    background-color: white;
    padding: 15px;
    width: 200px;
    left: -50px;
    z-index: 999999;
    opacity: .5;
}

.nav-item .parent_menu .parent_menu_item {
    position: relative;
}

.nav-item .parent_menu .sub_menu {
    visibility: hidden;
    position: absolute;
    background-color: white;
    padding: 15px;
    width: 200px;
    left: 120%;
    z-index: 999999;
    top: 0px;
}

.nav-item .parent_menu .parent_menu_item:hover .sub_menu {
    visibility: visible;
    left: 100%;
    transition: all .5s;
}



.nav-item:hover .parent_menu {
    position: absolute;
    visibility: visible;
    margin-top: -5px;
    opacity: 1;
    transition: all .3s;
}

.li {
    list-style: none;
}

a {
    color: rgba(54, 53, 53, 0.908);
    font-size: 15px;
    text-decoration: none;
}

.nav-link,
.nav-link:focus,
.nav-link:hover {
    color: rgba(54, 53, 53, 0.908);
}

.right-nav a {
    margin-right: 10px;
}

a:hover,
a:active,
a.active {
    color: black;
    font-weight: 300;
}

.nav-item {
    height: inherit;
}

.nav-item .nav-link {
    font-weight: 500;
}

@media all and (max-width: 992px) {
    .navbar .nav-item .connect .connectBtn button {
        padding: 3px 6px !important;
    }

    .navbar .nav-item .nav-link {
        padding: 2px !important;
    }

    .navbar .nav-item.log {
        padding: 5px 0px !important;
    }

    .navbar .right-nav {
        padding: 10px 0px;
    }
}

@media all and (min-width: 992px) {
    /* .navbar .nav-item .dropdown-menu {
        display: none;
        margin-top: 0px !important;
    } */

    .navbar .nav-item .nav-link {
        /* color: rgba(54, 53, 53, 0.908); */
        font-size: 15px;
    }

    /* .navbar .nav-item:hover .nav-link,
    .active {
        color: black;
    } */

    /* .navbar .nav-item:hover .dropdown-menu {
        right: 0;
        position: absolute;
        display: block;
    } */

    /* .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    } */
}

/* .navbar .nav-item .nav-link.active {
    color: black !important;
} */

/* .dropdown-menu .dropdown-item {
    font-weight: 400 !important;
    color: rgba(54, 53, 53, 0.908);
    background: #fff;
} */

/* .dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:visited,
.dropdown-menu .dropdown-item:link {
    color: black;
    background: #fff;
} */

/* .dropdown-menu {
    margin-top: 10px;
    padding-left: 10px;
} */

.navbar-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(54, 53, 53, 0.908);
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }

    .nav-item .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }

    .nav-item .submenu-left {
        right: 100%;
        left: auto;
    }

    .dropdown-menu>li:hover {
        background-color: #f1f1f1
    }

    .dropdown-menu>li:hover>.submenu {
        display: block;
    }
}

.relative .nav-link {
    color: white;
}

.social-link {
    color: #7b3f00;
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
/* @media (max-width: 991px) {
    .dropdown-menu .dropdown-menu {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        margin-bottom: .5rem;
    }
} */

/* a.dropdown-item {
    cursor: pointer;
}

.dropdown:hover>.dropdown-menu {
    display: block;
    position: absolute;
    left: 50;
} */