.MuiButtonBase-root {
    text-transform: lowercase;
    justify-content: flex-start;
}
/* span.org-title {
    text-transform: lowercase;
}
span.org-title::first-letter {
    text-transform: uppercase;
} */
span.org-name {
    text-transform: none;
}
button.MuiButtonBase-root {
    width: fit-content;
    padding: 8px;
    text-transform: capitalize;
}
.org-link:hover {
    cursor: pointer;
}
.pi-img-wrapper {
    /* text-align: center; */
    display: flex;
    justify-items: center;
    align-items: center;
}
.pi-img-wrapper img {
    height: 200px;
    width: 100%;
}
.project-item {
    width: 100%;
}
.card {
    align-items: center !important;
}
.project-name-wrapper{
    color: white;
    background-color: black !important;
    width: 100%;
}
.project-name {
    word-wrap: break-word;
    margin-top: 0px;
}
.org-name-btn {
    color: black;
    font-size: 17.5px;
    font-weight: 500;
    background-color: #76C4C780;
    word-wrap: break-word;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    box-shadow: 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.org-name-btn::first-letter {
    text-transform: uppercase;
}
.org-name-btn:hover {
    color: white;
    background-color: #7F8F83;
}