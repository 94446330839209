body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#environmental-impact {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width : 1200px) {

  #environmental-impact {
    height: 280px !important;
  }

}



.apexcharts-legend.apx-legend-position-bottom {
  flex-wrap: wrap;
}

.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: center;
}