.pro_container {
    width: 83%;
}
.title1{
    color: #565555;
    font-weight: bold;
}
.value1{
    color: #565555;
}
.pro_name h3 {
    color: #008F0080;
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight: 400;
    padding-bottom: 10px;
}
.price_cate {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
    /* padding: 10px; */
}

.price_cate .pro_price,
.price_cate .pro_cate {
    padding: 10px 15px;
    display: flex;
    justify-content: start;
    align-items: start;
    /* gap: 20px; */
    /* background-color: gray; */
    /* border: 1px solid #ebe7e7a8; */
    transition: opacity .6s cubic-bezier(0.16, 1, 0.3, 1), transform .6s cubic-bezier(0.16, 1, 0.3, 1);
    transform: translateX(0);
    transition-delay: 300ms;
    color: #565555;
}

.form_image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.pro_image img {
    width: 100%;
    height: 100%;
    border: 1px solid #ebe7e7a8;
}

.pro_form {
    padding: 10px;
    border: 1px solid #ebe7e7a8;
}

@media only screen and (max-width:1024px) {
    .pro_container {
        width: 90%;
    }

    .price_cate .pro_price,
    .price_cate .pro_cate {
        flex-direction: column !important;
    }

    .price_cate .pro_price .clone,
    .price_cate .pro_cate .clone {
        display: none;
    }

    .form_image {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (max-width:768px) {
    .pro_container {
        padding: 10px;
    }

    .price_cate {
        grid-template-columns: repeat(1, 1fr);
    }

    .price_cate .pro_price,
    .price_cate .pro_cate {
        padding-left: 0px;
    }

    .pro_name h3 {
        font-size: 25px;
        text-align: left !important;
    }

    .price_cate .pro_price h4,
    .price_cate .pro_cate h4 {
        font-size: 22px;
        font-weight: 500;
    }

    .price_cate .pro_price h5,
    .price_cate .pro_cate h5 {
        font-size: 16px;
        font-weight: 400;
    }

    .price_cate {
        gap: 0px
    }
}

.sdgs-slide {
    position: relative;
}

.sdgs-slide .left {
    width: 20px;
    height: 20px;
    background-color: green;
    position: absolute;
    left: 4px;
    height: 100%;
    top: 0px;
    border: none;
    outline: none;
    font-size: 20px !important;
    opacity: .3;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    display: none;
}

.sdgs-slide .right {
    width: 20px;
    height: 20px;
    background-color: green;
    position: absolute;
    right: 4px;
    height: 100%;
    top: 0px;
    border: none;
    outline: none;
    font-size: 20px !important;
    opacity: .3;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    display: none;
}

.sdgs-image {
    position: relative;
}

.sdgs-image span {
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px 8px;
    background-color: #76C4C780;
    color: white;
    font-size: 13px;
}

.project-title {
    font-size: 28px;
}

@media only screen and (max-width:480px) {
    .project-title {
        font-size: 20px !important;
    }
}

@media only screen and (max-width:330px) {
    .project-title {
        font-size: 18px !important;
    }
}
.react-multiple-carousel__arrow {
    z-index: 1 !important;
}