footer {
    clear: both;
    position: relative;
    margin-bottom: 0px;
    /* background-color: #e7e7e7d1; */
    font-size: 15px;
}

footer a {
    font-size: 15px;
    font-weight: normal;
    color: #212529;
}

footer a:hover {
    color: #212529;
}

footer a:hover {
    font-weight: normal !important;
}

.about,
.refund,
.guide {
    font-size: 15px;
    font-weight: 400;
    color: #212529;
    cursor: pointer;
    text-align: center;
    z-index: 5 !important;
}

.about-model.show,
.refund-model.show,
.guide-model.show {
    scale: 1;
}

.about-model,
.refund-model,
.guide-model {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    scale: 0;
    transition: all .5s;
    transform-origin: top;
    padding: 0px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

}

.about-header,
.refund-header,
.guide-header {
    padding: 7px 10px;
    border-bottom: 1px solid rgb(224, 223, 223);
    text-align: center;
    width: 100%;
    color: black;
    font-weight: 600;
    position: relative;
}

.about-header .close,
.refund-header .close,
.guide-header .close {
    position: absolute;
    right: 15px;
    top: 6px;
    cursor: pointer;
}

.about-model .main-con,
.refund-model .main-con,
.guide-model .main-con {
    width: 90%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 8px -1px #c5c5c5;
}

.guide-model .main-con .guide-body {
    height: 75vh;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.about-model .main-con .about-body,
.refund-model .main-con .refund-body,
.guide-model .main-con .guide-body {
    padding: 35px;
}

@media only screen and (min-width: 991px) {

    .about-model .main-con,
    .refund-model .main-con,
    .guide-model .main-con {
        width: 45%;
    }
}

.about-model .main-con .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.about-model .main-con .image img {
    width: 140px;
}

.about-model .main-con p,
.refund-model .main-con p,
.guide-model .main-con p {
    font-size: 15px;
    color: #404040;
    text-align: start;
    padding: 0px 8px;
}

.guide-model .main-con h4 {
    font-size: 18px;
    color: #404040;
}

.guide-model .main-con p {
    padding: 0px 0px;
}

.about-model .main-con .btn {
    background-color: #008F0052;
    cursor: pointer;
}

.about-model .main-con .btn:hover {
    background-color: #B6CDBC;
    color: white;
    box-shadow: 0px 3px 6px -2px #B6CDBC;
}

.about-model .main-con .btn a:hover {
    color: white;
}

.about-model .main-con .btn a {
    color: white;
    font-weight: 500;
    background-color: #7B3F00;
}

.about-model .main-con span,
.refund-model .main-con span,
.guide-model .main-con span {
    font-size: 20px;
    text-align: center;
}

.refund-bottom {
    margin: 20px;
}

.refund-model .main-con .refund-bottom span {
    font-size: 14px;
}