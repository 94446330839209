p {
    margin-bottom: 10px !important;
}
.title {
    color: rgba(54, 53, 53, 0.908);
    /* font-weight: 400; */
}
.value {
    color: #494848;
    /* margin-top: 0px; */
    margin-bottom: 15px;
}
.line_org{
    width: 100%;
    height: 1px;
    background-color:  #6d6b6b7c;
    margin: 16px 0px;
}